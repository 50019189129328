
import { Vue, Component } from 'vue-property-decorator';
import { User } from '@/models';
import { userService } from '../services';

@Component
export default class Profile extends Vue {
  username = this.$store.getters['user/username'];
  userAvatar = this.$store.getters['user/avatar'];
  formValid = true;

  get user(): User {
    return this.$store.getters['user/user'];
  }

  created() {
    document.title = 'AgileMate Profile';
    if (!this.$store.getters['user/authenticated']) {
      this.$router.push({ name: 'login', query: { redirectFrom: this.$route.fullPath } }).catch(() => null); // Prevent router navigation guard error
    }
  }

  async saveProfile() {
    //this.$store.dispatch('user/setUsername', this.username);
    if (!this.username) return;
    await userService.setName(this.username);
    this.$store.dispatch('user/refreshUser');
    this.$bus.$emit('snackbar-notify', 'Profile successfully stored', 'success');
    this.$analytics.logEvent('update_profile', {
      user_id: this.user.id,
      username: this.username,
      avatar: this.userAvatar,
    });
    const redirectFrom = this.$route.query['redirectFrom'] as string;
    const nextRoute = redirectFrom || 'home';
    this.$router.push(nextRoute).catch(() => null); // Prevent router navigation guard error
  }

  logout() {
    const redirectFrom = (this.$route.query['redirectFrom'] as string) || 'home';
    const nextRoute = { name: 'logout', query: { redirectFrom } };
    this.$router.push(nextRoute).catch(() => null); // Prevent router navigation guard error
  }

  close() {
    const redirectFrom = this.$route.query['redirectFrom'] as string;
    const nextRoute = redirectFrom || 'home';
    this.$router.push(nextRoute).catch(() => null); // Prevent router navigation guard error
  }
}
