
import { Vue, Component } from 'vue-property-decorator';
import { User, Users } from '@/models';
import { userService } from '../services';

@Component
export default class Login extends Vue {
  step = 1;
  email = '';
  emailRules = [(v) => !!v || 'Email is required', (v) => /\S+@\S+\.\S+/.test(v) || 'Email must be valid'];
  password = '';
  passwordRules = [(v) => !!v || 'Password is required', (v) => (v && v.length >= 8) || 'Password too short'];
  username = this.$store.getters['user/username'];
  usernameRules = [
    (v) => !!v || 'Username is required',
    (v) => (v && v.length >= 3) || 'Username should be 3 characters or longer',
    (v) => (v && !v.match(/anonym/gi)) || 'Anonymous usernames are not supported😜',
  ];
  firstName = '';
  lastName = '';
  anonymous = false;
  formValid = true;
  users: Users = [];
  user = {} as User;
  existingUser = {} as User;

  created() {
    this.$bind('users', userService.usersRef);
    document.title = 'AgileMate Login';
  }

  async anonymousLogin() {
    await this.loginOrRegister(true);
  }

  async loginOrRegister(anonymous: boolean) {
    if (anonymous) {
      try {
        if (!this.username) return;
        this.anonymous = true;
        this.formValid = false;
        const result = await userService.loginAnonymously();
        if (result.user) {
          this.$bus.$emit('snackbar-notify', 'Successfully authenticated', 'success');
          await userService.updateProfile({ displayName: this.username });
          await this.$store.dispatch('user/refreshUser');
          this.$analytics.logEvent('login', {
            user_id: result.user.uid,
            username: this.username,
            email: this.user.email,
            provider: 'Anonymous',
          });
          this.redirect(result.user);
        }
      } catch (error) {
        this.$bus.$emit('snackbar-notify', error, 'error');
      }
    } else {
      this.user.email = this.email;
      const user = await userService.getUserFromCollection(this.email);
      if (!user) {
        this.step = 2;
      } else {
        this.user = user;
        this.step = 3;
        this.$analytics.logEvent('login', {
          user_id: this.user.id,
          username: this.user.username,
          email: this.user.email,
          provider: 'Firebase',
        });
      }
    }
  }

  async register() {
    try {
      this.formValid = false;
      const result = await userService.createUserWithEmailAndPassword(this.email, this.password);
      if (result.user) {
        this.user.firstName = this.firstName;
        this.user.lastName = this.lastName;
        this.user.username = `${this.firstName} ${this.lastName}`;
        this.user.id = result.user.uid;
        this.user.avatar = `https://robohash.org/${result.user.uid}.png`;
        await userService.updateProfile({
          displayName: this.user.username,
          photoURL: this.user.avatar,
        });
        await this.$store.dispatch('user/refreshUser');
        await userService.addUserToCollection();
        this.$bus.$emit('snackbar-notify', 'Account successfully created', 'success');
        this.$analytics.logEvent('sign_up', {
          user_id: this.user.id,
          username: this.user.username,
          email: this.user.email,
          provider: 'Firebase',
        });
        await this.loginWithEmail();
      }
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  async loginWithEmail() {
    try {
      this.formValid = false;
      const result = await userService.loginWithEmail(this.email, this.password);
      this.$bus.$emit('snackbar-notify', 'Successfully authenticated', 'success');
      this.$analytics.logEvent('login', {
        user_id: this.user.id,
        username: this.user.username,
        email: this.user.email,
        provider: 'Firebase',
      });
      await this.redirect(result.user);
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  async loginWithGoogle() {
    try {
      this.formValid = false;
      const result = await userService.loginWithGoogle();
      this.$bus.$emit('snackbar-notify', 'Successfully authenticated', 'success');
      this.$analytics.logEvent('login', {
        user_id: this.user.id,
        username: this.user.username,
        email: this.user.email,
        provider: 'Google',
      });
      await this.redirect(result.user);
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  async resetPassword() {
    try {
      this.formValid = false;
      const result = await userService.requestPasswordReset(this.email);
      this.$bus.$emit('snackbar-notify', 'Successfully requested password reset', 'success');
      this.$analytics.logEvent('pwdreset', {
        user_id: this.user.id,
        username: this.user.username,
        email: this.user.email,
        provider: 'Firebase',
      });
      await this.redirect(this.user);
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  async redirect(user) {
    if (user) {
      //await this.$store.dispatch('user/login', user);
      const nextRoute = this.$route.query['redirectFrom'] as string;
      await this.$router.replace(nextRoute || 'home').catch(() => null); // Prevent router navigation guard error
    }
  }
}
